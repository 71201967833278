/**
 * High level router.
 *
 * Note: It's recommended to compose related routes in internal router
 * components (e.g: `src/app/modules/Auth/pages/AuthPage`, `src/app/BasePage`).
*/
import React, { lazy, Suspense } from "react";
import { Switch, Route, Redirect, useLocation, useRouteMatch } from "react-router-dom";// 
import { shallowEqual, useSelector } from "react-redux";

import { Layout, LayoutSplashScreen } from "../_metronic/layout";
import BasePage from "./BasePage";
import { Logout, AuthPage } from "./modules/Auth";
import UnderConstruction from "./modules/ErrorsExamples/UnderConstruction";
import Maintenance from './modules/ErrorsExamples/Maintenance'
import OpenAIChat from "./components/open-ai-chat";
import Forbidden from "./modules/ErrorsExamples/Forbidden";
import { useGetPolicyConfig } from "@states/Policy.state.js"

const ResetPasswordPage = lazy(() => import('./modules/Auth/pages/ResetPassword'));
const SetupMfa = lazy(() => import('./modules/Auth/pages/SetupMfa'));
const PasswordExpired = lazy(() => import('./modules/Auth/pages/PasswordExpired'));

const WHITE_LIST_SIDEBAR = ['/item-editor/:id', '/task-editor/:id', '/form-editor/:id']

const WHITE_LIST_CHAT = [
	'/item-editor/:id',
	'/task-editor/:id',
	'/form-editor/:id',
	'/schedule-editor/:id',
	'/data-connector/advance',
	'/data-connector/advance/:id',
	'/data-connector/simple',
	'/data-connector/simple/:id',
	'/referencedata/:id',
	'/report-designer',
	'/robotic-automation/add',
	'/robotic-automation/:id',
]

export function Routes() {
	const matchRoute = useRouteMatch(WHITE_LIST_SIDEBAR)
	const whiteListChat = useRouteMatch(WHITE_LIST_CHAT)
	const { isAuthorized, authToken, accessToken } = useSelector(
		({ auth }) => ({
			isAuthorized: auth.authToken != null,
			authToken: auth.authToken,
			accessToken: auth.config?.accessToken,
		}),
		shallowEqual
	);
	const { data } = useGetPolicyConfig(authToken, false)

	const isRequireMfa = data?.data?.config?.securityFeature.requireMFA
	const isTwoFactorEnabled = data?.data?.two_factor_enabled
	const isPasswordExpired = data?.data?.passwordExpired
	const isEnablePasswordExpiry = data?.data?.enablePasswordExpiry

	const location = useLocation();
	const returnUrl = `${location.pathname}${location.search}${location.hash}`;
	// eslint-disable-next-line
	const redirectUrl = () => {
		if (location.pathname === "/" || location.pathname === "/logout") {
			return '/authentication/login'
		} else {
			return `/authentication/login?ReturnUrl=${encodeURI(returnUrl)}`
		}
	};

	const redirectTo = new URLSearchParams(window.location.search).get('ReturnUrl')

	const authRedirect = () => {
		if (!isAuthorized) {
			return <Route>
				<AuthPage redirectUrl={redirectUrl()} />
			</Route>
		} else if (!isTwoFactorEnabled && isRequireMfa) {
			return <Redirect to='/authentication/setup-mfa' />
		} else if (isEnablePasswordExpiry && isPasswordExpired) {
			return <Redirect to={`/authentication/password-expired?id=${accessToken}`} />
		} else {
			return <Redirect from="/authentication" to={redirectTo ?? "/"} />
		}
	}

	const authLayoutBase = () => {
		if (matchRoute !== null) {
			return <BasePage />
		} else {
			return (
				<Layout>
					{!whiteListChat && <OpenAIChat />}
					<BasePage />
				</Layout>
			)
		}
	}

	return (
		<Suspense fallback={<LayoutSplashScreen />}>
			<Switch>
				<Route path="/reset-password">
					<ResetPasswordPage isAuthorized={isAuthorized} />
				</Route>
				<Route path="/authentication/setup-mfa">
					<SetupMfa isAuthorized={isAuthorized} />
				</Route>
				<Route path="/authentication/password-expired">
					<PasswordExpired isAuthorized={isAuthorized} />
				</Route>
				<Route path="/maintenance" component={Maintenance} />
				<Route path="/403" component={Forbidden} />
				<Route path="/error" component={UnderConstruction} />
				<Route path="/logout" component={Logout} />
				{authRedirect()}
				{authLayoutBase()}
			</Switch>
		</Suspense>
	);
}