const botEndpoints = {
    trackGenAiUsage({ moduleId, data }) {
        return {
            url: `/api/bot/genaiusage?moduleId=${moduleId}`,
            method: 'POST',
            data,
        }
    },
    trackBotUsage({ botId, startDate, endDate }) {
        return {
            url: `/api/bot/usage/datatables?start=${startDate}&end=${endDate}`,
            method: 'POST',
            data: {
                columns: [
                    {
                        index: 0,
                        data: '',
                        name: '',
                    }
                ],
                start: 0,
                draw: 0,
                page: 0,
                length: 1000,
                filters: {
                    rules: [{
                        field: "bot_id",
                        value: botId,
                        operator: "contains"
                    }]
                }
            },
        }
    },
}

export default botEndpoints